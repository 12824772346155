import { ENV } from 'lib/react_on_rails/env';
import { useClientHasRendered } from './use_client_has_rendered';

export const useEnvVars = () => {
  const clientHasRendered = useClientHasRendered();

  if (!clientHasRendered || !ENV) return {};

  return ({
    billingPagePaidSubContactUrl: ENV.BILLING_PAGE_PAID_SUB_CONTACT_URL,
    billingPageFreeTrialContactUrl: ENV.BILLING_PAGE_FREE_TRIAL_CONTACT_URL,
    chiliPiperRouterKey: ENV.CHILIPIPER_TEAMS_PLANS_INBOUND_ROUTER_KEY,
    isWorkspaceEmailStatsEnabled: ENV.WORKSPACE_EMAIL_STATS_ENABLED,
    isSubscriptionSelfServeEnabled: ENV.SUBSCRIPTION_SELF_SERVE_ENABLED,
    isPageThemesEnabled: ENV.PAGE_THEMES_ENABLED,
    cloudflareTurnstileCaptchaSiteKey: ENV.CLOUDFLARE_TURNSTILE_CAPTCHA_SITE_KEY,
    cloudflareTurnstileCaptchaEnabled: ENV.CLOUDFLARE_TURNSTILE_CAPTCHA_ENABLED,
    participantQnrSignupBuffer: ENV.PARTICIPANT_QNR_SIGNUP_BUFFER,
    siteBaseUrl: ENV.SITE_BASE_URL,
    paragonConfig: ENV.PARAGON_CONFIG,
  });
}
